// src/pages/StagePage.js
import React from "react";

export default function StageNavBar({ stage }) {
  const headerStyle = {
    backgroundColor: stage.colors.header,
  };

  return (
    <nav
      style={headerStyle}
      className="flex items-center justify-start p-4 shadow-lg min-h-[80px]"
    >
      {stage.logo_url ? (
        <img src={stage.logo_url} alt="Event Logo" className="h-8 mr-4" />
      ) : (
        <h1 className="text-lg font-semibold">{stage.title}</h1>
      )}
    </nav>
  );
}
