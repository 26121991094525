// src/components/Dashboard/StageTable.js
import { Dialog, Transition } from "@headlessui/react"; // For the modal dialog
import { ClipboardIcon, TrashIcon, ExclamationCircleIcon } from "@heroicons/react/24/outline"; // For icons
import {
  collection,
  deleteDoc,
  doc,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext"; // Correct import path
import { firestore, functions } from "../../firebase"; // Correct import path

export default function StageTable() {
  const { currentUser, accountData } = useAuth(); // Get the logged-in user and account data
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null); // For delete confirmation modal
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false); // Modal visibility state

  // Function to call the Firebase Cloud Function
  const toggleEventPublicStatus = async (eventId, isPublic) => {
    try {
      const togglePublicStatus = httpsCallable(
        functions,
        "toggleEventPublicStatus"
      );
      const result = await togglePublicStatus({ eventId, isPublic });
      console.log(result.data.message);
      return result.data; // This will return the updated status or an error message
    } catch (error) {
      console.error("Error toggling public status:", error.message);
      alert(error.message); // Show error to the user if there's an issue
    }
  };

  useEffect(() => {
    if (!currentUser || !accountData) return; // Ensure currentUser and accountData exist

    // Query Firestore for events with the current user's user_id and account_id
    const eventsRef = collection(firestore, "events");
    const q = query(
      eventsRef,
      where("user_id", "==", currentUser.uid), // Filter by user_id
      where("account_id", "==", accountData.id) // Filter by account_id
    );

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const eventsData = snapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      setEvents(eventsData); // Set events in state
    });

    return () => unsubscribe(); // Cleanup listener on component unmount
  }, [currentUser, accountData]);

  // Function to delete a stage
  const handleDelete = async () => {
    if (selectedEvent) {
      try {
        await deleteDoc(doc(firestore, "events", selectedEvent.id));
        setTimeout(() => {
          setIsDeleteModalOpen(false);
          setSelectedEvent(null);
        }, 200); // Match the transition duration
      } catch (error) {
        console.error("Error deleting stage:", error);
        // Optionally, you can show an error message to the user
      }
    }
  };

  // Function to copy URL to clipboard
  const handleCopyURL = (url) => {
    navigator.clipboard
      .writeText(url)
      .then(() => {
        // Optionally, show a success message
      })
      .catch((err) => {
        console.error("Failed to copy:", err);
      });
  };

  const [loadingEventId, setLoadingEventId] = useState(null); // To track loading state for the specific event being updated

  const handleToggleClick = async (event) => {
    setLoadingEventId(event.id);
    const newPublicStatus = !event.is_public; // Toggle the current public status
    const result = await toggleEventPublicStatus(event.id, newPublicStatus);

    if (result) {
      // Here you would ideally update the UI state to reflect the change.
      // Since this example doesn't include full state management, we're simply logging the result.
      console.log(
        `Event ${event.id} public status changed to:`,
        newPublicStatus
      );
    }

    setLoadingEventId(null); // Reset the loading state after the function completes
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      {/* Header */}
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h2 className="text-xl font-semibold leading-7 text-gray-900">
            Your Stages
          </h2>
          <p className="mt-2 text-sm text-gray-700">
            A list of all the stages you have created including their name,
            custom slug, and creation date.
          </p>
        </div>
      </div>

      {/* Table */}
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                {/* Table Head */}
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      Stage Name
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Stage URL
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Created Date
                    </th>
                    <th
                      scope="col"
                      className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                    >
                      <span className="sr-only">Actions</span>
                    </th>
                  </tr>
                </thead>

                {/* Table Body */}
                <tbody className="divide-y divide-gray-200 bg-white">
                  {events.length > 0 ? (
                    events.map((event) => {
                      const stageURL = `https://app.vdopage.com/s/${event.custom_slug}`;
                      return (
                        <tr key={event.id}>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                            {event.event_name}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 flex items-center">
                          <button
                            className="text-gray-400 hover:text-gray-600 mr-2"
                            onClick={() => handleCopyURL(stageURL)}
                            title="Copy URL"
                          >
                            <ClipboardIcon className="h-5 w-5" aria-hidden="true" />
                          </button>
                          <span>{stageURL}</span>
                        </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {event.created_date && event.created_date.seconds
                              ? new Date(
                                  event.created_date.seconds * 1000
                                ).toLocaleDateString()
                              : "N/A"}
                          </td>
                          <td className="whitespace-nowrap py-4 pl-3 pr-4 text-sm font-medium sm:pr-6">
                            <div className="flex space-x-4 justify-end">
                              {/* Preview Stage */}
                              <Link
                                to={`/p/${event.custom_slug}`}
                                className="text-gray-500 hover:text-gray-700 w-26 text-left" // Set fixed width
                              >
                                Preview
                              </Link>

                              {/* Go To Stage / Not Published */}
                              <Link
                                to={`/s/${event.custom_slug}`}
                                className="w-32 text-left" // Set fixed width to prevent text shift
                              >
                                {event.is_public ? (
                                  <span className="text-green-700">Go To Stage</span>
                                ) : (
                                  <div className="flex items-center text-gray-500" title="Not viewable">
                                    <ExclamationCircleIcon className="h-5 w-5 text-red-500 mr-1" />
                                    Not Published
                                  </div>
                                )}
                              </Link>

                              {/* Make Private / Make Public */}
                              <button
                                onClick={() => handleToggleClick(event)}
                                disabled={loadingEventId === event.id}
                                className={`w-24 text-left hover:text-indigo-900 ${loadingEventId === event.id ? "opacity-50" : event.is_public ? "text-red-400" : "text-green-600"}`} // Set fixed width
                              >
                                {loadingEventId === event.id
                                  ? "Updating..."
                                  : event.is_public
                                  ? "Make Private"
                                  : "Make Public"}
                              </button>

                              {/* Delete Button */}
                              <button
                                onClick={() => {
                                  setSelectedEvent(event);
                                  setIsDeleteModalOpen(true);
                                }}
                                className="text-red-600 hover:text-red-900 flex items-center justify-end w-8" // Set width for icon alignment
                              >
                                <TrashIcon className="h-5 w-5" aria-hidden="true" />
                              </button>
                            </div>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td
                        colSpan="4"
                        className="whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-500 text-center"
                      >
                        No stages found.
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      {/* Delete Confirmation Modal */}
      <Transition.Root show={isDeleteModalOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => setIsDeleteModalOpen(false)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-6 pt-5 pb-4 text-left shadow-xl transition-all sm:w-full sm:max-w-lg sm:p-6">
                  <div>
                    <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-red-100">
                      <TrashIcon
                        className="h-6 w-6 text-red-600"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 text-center">
                      <Dialog.Title
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-900"
                      >
                        Delete Stage
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          Are you sure you want to delete the stage{" "}
                          <span className="font-medium text-gray-900">
                            {selectedEvent && selectedEvent.event_name}
                          </span>
                          ? This action cannot be undone.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:flex sm:justify-center sm:space-x-4">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-red-600 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:w-auto"
                      onClick={handleDelete}
                    >
                      Delete
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-gray-200 px-4 py-2 text-sm font-semibold text-gray-700 shadow-sm hover:bg-gray-300 sm:mt-0 sm:w-auto"
                      onClick={() => setIsDeleteModalOpen(false)}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}
