// src/components/Billing/BillingModal.js
import { addDoc, collection, onSnapshot } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import React, { useState } from "react"; // Import useState
import { useAuth } from "../../contexts/AuthContext";
import { firestore, functions } from "../../firebase";

const PORTAL_ID = ""; // Optional ID of a portal configuration
const MONTHLY_PRICE_ID = "price_1Q5IL6IwkAI8JQRmDHsa9HMq";
const ANNUAL_PRICE_ID = "price_1Q5fzhIwkAI8JQRmsSz1p1VI";

export default function BillingModal({ isOpen, onClose }) {
  if (!isOpen) return null;
  const { currentUser, isSubscribed } = useAuth(); // Get the logged-in user and subscription status

  // Add loading state
  const [loading, setLoading] = useState(false);

  // Function to handle Billing Portal button click
  const handleBillingPortal = async () => {
    setLoading(true); // Set loading to true
    try {
      const functionRef = httpsCallable(
        functions,
        "ext-firestore-stripe-payments-createPortalLink"
      );

      const { data } = await functionRef({
        returnUrl: window.location.origin,
        locale: "auto", // Optional, defaults to "auto"
        configuration: PORTAL_ID,
      });
      window.location.assign(data.url);
    } catch (error) {
      alert(`An error occurred: ${error.message}`);
      setLoading(false); // Reset loading state on error
    }
  };

  // Function to handle Subscribe Monthly button click
  const handleSubscribeMonthly = async () => {
    setLoading(true); // Set loading to true
    await checkoutWithPrice(MONTHLY_PRICE_ID);
  };

  // Function to handle Subscribe Annual button click
  const handleSubscribeAnnual = async () => {
    setLoading(true); // Set loading to true
    await checkoutWithPrice(ANNUAL_PRICE_ID);
  };

  // Function to create a checkout session and redirect
  const checkoutWithPrice = async (priceId) => {
    try {
      console.log("user id", currentUser.uid);

      const checkoutSessionsRef = collection(
        firestore,
        "customers",
        currentUser.uid,
        "checkout_sessions"
      );

      // Adding a new document to the checkout_sessions collection
      const docRef = await addDoc(checkoutSessionsRef, {
        price: priceId,
        success_url: window.location.origin,
        cancel_url: window.location.origin,
      });

      // Listening for changes on the added document (real-time updates)
      onSnapshot(docRef, (snap) => {
        const data = snap.data();
        if (data) {
          const { error, url } = data;
          if (error) {
            alert(`An error occurred: ${error.message}`);
            setLoading(false); // Reset loading state on error
          }
          if (url) {
            // We have a Stripe Checkout URL, let's redirect.
            window.location.assign(url);
          }
        }
      });
    } catch (error) {
      alert(`An error occurred: ${error.message}`);
      setLoading(false); // Reset loading state on error
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      {/* Modal overlay */}
      <div className="fixed inset-0 bg-black opacity-50"></div>

      {/* Modal content */}
      <div className="bg-white p-6 rounded-lg shadow-lg relative z-10 max-w-4xl w-full">
        {/* Modal header */}
        <div className="mb-4">
          <h2 className="text-2xl font-semibold text-center">Billing</h2>
        </div>

        {/* Subscription Message */}
        <div className="mb-6 text-center">
          {isSubscribed ? (
            <p className="text-green-600 text-lg font-semibold">
              Thank you for your active subscription!
            </p>
          ) : (
            <h3 className="text-xl font-semibold text-blue-600">
              Ready to unlock unlimited stages and all new features?
            </h3>
          )}
        </div>

        {/* Loader when loading */}
        {loading ? (
          <div className="flex flex-col items-center">
            <h2 className="text-4xl font-bold text-gray-800 mb-4">
              Loading...
            </h2>
            <p className="text-base text-gray-600 mb-4">
              Please be patient, this can take a few seconds.
            </p>
            <div className="flex gap-2">
              <div className="w-6 h-6 rounded-full bg-green-500 animate-bounce"></div>
              <div className="w-6 h-6 rounded-full bg-green-500 animate-bounce [animation-delay:-0.2s]"></div>
              <div className="w-6 h-6 rounded-full bg-green-500 animate-bounce [animation-delay:-0.4s]"></div>
            </div>
          </div>
        ) : (
          <>
            {/* Modal body */}
            {!isSubscribed && (
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                {/* Monthly Subscription Card */}
                <div className="border p-6 rounded-lg shadow-sm">
                  <h3 className="text-xl font-semibold mb-2 text-center">
                    Monthly Subscription
                  </h3>
                  <p className="text-sm text-gray-600 mb-4 text-center">
                    Subscriptions unlock immediately, allowing unlimited stages
                    and access to all new features.
                  </p>
                  {/* Price */}
                  <div className="flex items-baseline justify-center text-gray-900 mb-4">
                    <span className="text-3xl font-semibold">$</span>
                    <span className="text-5xl font-extrabold tracking-tight">
                      4.99
                    </span>
                    <span className="text-xl font-normal text-gray-500 ml-1">
                      /month
                    </span>
                  </div>
                  {/* Subscribe Monthly Button */}
                  <button
                    className="bg-green-500 text-white text-sm px-5 py-2.5 rounded-lg w-full"
                    onClick={handleSubscribeMonthly}
                  >
                    Subscribe Monthly
                  </button>
                </div>

                {/* Annual Subscription Card */}
                <div className="border p-6 rounded-lg shadow-sm">
                  <h3 className="text-xl font-semibold mb-2 text-center">
                    Annual Subscription
                  </h3>
                  <p className="text-sm text-gray-600 mb-4 text-center">
                    Subscriptions unlock immediately, allowing unlimited stages
                    and access to all new features.
                  </p>
                  {/* Price */}
                  <div className="flex items-baseline justify-center text-gray-900 mb-4">
                    <span className="text-3xl font-semibold">$</span>
                    <span className="text-5xl font-extrabold tracking-tight">
                      49.99
                    </span>
                    <span className="text-xl font-normal text-gray-500 ml-1">
                      /year
                    </span>
                  </div>
                  {/* Subscribe Annual Button */}
                  <button
                    className="bg-green-500 text-white text-sm px-5 py-2.5 rounded-lg w-full"
                    onClick={handleSubscribeAnnual}
                  >
                    Subscribe Annually
                  </button>
                </div>
              </div>
            )}

            {/* Billing Portal Section */}
            <div className="mt-8 p-4 border-t border-gray-200">
              <h3 className="text-md font-medium text-gray-900">
                Go to your Billing Portal
              </h3>
              <p className="text-sm text-gray-600 mb-4">
                {isSubscribed
                  ? "Manage your subscription, update payment details, or view invoices in the billing portal."
                  : "To change your card details, manage your subscription, get invoices, or make any changes to your account, please visit your billing portal."}
              </p>
              <button
                className="bg-blue-500 text-white text-sm px-5 py-2.5 rounded-lg"
                onClick={handleBillingPortal}
              >
                Billing Portal
              </button>
            </div>

            {/* Modal footer with Close Button */}
            <div className="mt-4 flex justify-end">
              <button
                className="bg-gray-500 text-white px-4 py-2 rounded"
                onClick={onClose}
              >
                Close
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
