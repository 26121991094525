// src/pages/PasswordProtection.js
import React, { useState } from "react";

export default function PasswordProtection({ checkPassword, passwordError }) {
  const [passwordInput, setPasswordInput] = useState("");

  let handleFormSubmit = (e) => {
    e.preventDefault();
    checkPassword(passwordInput);
  };

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-8 shadow-lg max-w-lg w-full text-center">
        <form onSubmit={handleFormSubmit}>
          <h2 className="text-lg font-semibold mb-4">Enter Password</h2>
          <input
            type="password"
            value={passwordInput}
            onChange={(e) => setPasswordInput(e.target.value)}
            required
            className="block w-full p-2 border rounded mb-4"
          />
          {passwordError && (
            <div className="text-red-500 mb-4">{passwordError}</div>
          )}
          <button type="submit" className="bg-blue-500 text-white p-2 rounded">
            Submit
          </button>
        </form>
      </div>
    </div>
  );
}
