// src/components/Dashboard/Navbar.js
import React, { useState } from 'react';
import { signOut } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { auth } from '../../firebase';
import {
  ArrowRightOnRectangleIcon,
  Bars3Icon,
  XMarkIcon,
} from '@heroicons/react/20/solid';

export default function Navbar({ onBillingClick }) {
  const navigate = useNavigate();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  async function handleLogout() {
    await signOut(auth);
    navigate('/login');
  }


  return (
    <nav id="dashboard-nav" className="bg-red-600 shadow">
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div className="flex justify-between items-center h-16">
        
        {/* Logo */}
        <div className="flex-shrink-0" style={{ marginLeft: '48px' }}>
          <img src="/img/logo.png" alt="Logo" className="h-8" />
        </div>

        {/* Desktop Menu - Aligned Right */}
        <div className="hidden md:flex items-center ml-auto">
          {/* Billing Button */}
          <button
            className="text-white px-3 py-2 mr-4 h-10 flex items-center justify-center"
            onClick={onBillingClick}
          >
            Billing
          </button>

          {/* Support Button */}
          <a
            href="https://discord.gg/KjNpZevaTp"
            target="_blank"
            rel="noopener noreferrer"
            className="text-white px-3 py-2 mr-4 h-10 flex items-center justify-center"
          >
            Support
          </a>

          {/* Log Out Button */}
          <button
            onClick={handleLogout}
            className="text-white px-3 py-2 h-10 flex items-center justify-center"
            aria-label="Log Out"
          >
            <ArrowRightOnRectangleIcon className="h-6 w-6 text-white" />
          </button>
        </div>

        {/* Mobile Menu Button */}
        <div className="flex md:hidden">
          <button
            onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
            className="text-white px-3 py-2 h-10 flex items-center justify-center"
            aria-label="Toggle Menu"
          >
            {isMobileMenuOpen ? (
              <XMarkIcon className="h-6 w-6 text-white" />
            ) : (
              <Bars3Icon className="h-6 w-6 text-white" />
            )}
          </button>
          </div>
        </div>
      </div>

 {/* Mobile Menu */}
 {isMobileMenuOpen && (
        <div className="md:hidden bg-[#FF3A20]">
          <div className="px-2 pt-2 pb-3 space-y-1">
            {/* Billing Button */}
            <button
              className="w-full text-left text-white px-3 py-2"
              onClick={() => {
                setIsMobileMenuOpen(false);
                onBillingClick();
              }}
            >
              Billing
            </button>

            {/* Support Button */}
            <a
              href="https://discord.gg/KjNpZevaTp"
              target="_blank"
              rel="noopener noreferrer"
              className="w-small text-left text-white px-3 py-2"
              onClick={() => setIsMobileMenuOpen(false)}
            >
              Support
            </a>

            {/* Log Out Button */}
            <button
              onClick={() => {
                setIsMobileMenuOpen(false);
                handleLogout();
              }}
              className="w-full text-left text-white px-3 py-2"
              aria-label="Log Out"
            >
              Log Out
            </button>
          </div>
        </div>
      )}
    </nav>
  );
}