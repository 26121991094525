// src/components/Generic/VideoPlayer.js
import Hls from "hls.js";
import Plyr from "plyr-react";
import "plyr-react/plyr.css";
import React, { useEffect, useRef } from "react";

export default function VideoPlayer({ video_url }) {
  const videoRef = useRef(null);

  // Function to check if the URL is a video file
  const isVideoFile = (url) => {
    const videoExtensions = [".mp4", ".m3u8", ".webm"];
    return videoExtensions.some((ext) => url.includes(ext));
  };

  // Function to check if the browser supports HLS
  const canPlayHLS = () => {
    const video = document.createElement("video");
    return video.canPlayType("application/vnd.apple.mpegurl");
  };

  useEffect(() => {
    if (video_url.includes(".m3u8") && videoRef.current) {
      if (Hls.isSupported() && !canPlayHLS()) {
        const hls = new Hls();
        hls.loadSource(video_url);
        hls.attachMedia(videoRef.current);
      }
    }
  }, [video_url]);

  const plyrProps = {
    source: {
      type: "video",
      sources: [
        {
          src: video_url,
          type: isVideoFile(video_url)
            ? `video/${video_url.split(".").pop()}`
            : undefined,
        },
      ],
    },
    options: {
      controls: ["play", "progress", "fullscreen"], // Customize the controls you want
    },
  };

  return (
    <div id="video-player">
      {isVideoFile(video_url) ? (
        video_url.includes(".m3u8") && !canPlayHLS() ? (
          <video ref={videoRef} controls style={{ width: "100%" }} />
        ) : (
          <Plyr {...plyrProps} />
        )
      ) : (
        <iframe
          title="video player"
          style={{ width: "100%" }}
          frameBorder="0"
          allow="accelerometer; autoplay; fullscreen; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerPolicy="strict-origin-when-cross-origin"
          allowFullScreen
          src={video_url}
        />
      )}
    </div>
  );
}
