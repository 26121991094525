// src/pages/Dashboard.js
import React, { useState } from "react";
import Navbar from "../components/Dashboard/NavBar";
import StageForm from "../components/Dashboard/StageForm";
import StageTable from "../components/Dashboard/StageTable";
import BillingModal from "../components/billing/BillingModal";

export default function Dashboard() {
  const [isBillingModalOpen, setIsBillingModalOpen] = useState(false);

  return (
    <>
      <Navbar onBillingClick={() => setIsBillingModalOpen(true)} />
      <div className="p-6 max-w-8l mx-auto">
        <h1 className="text-2xl font-semibold leading-7 text-gray-900 mb-8">
          Dashboard
        </h1>

        <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
          <div className="lg:col-span-1 bg-white p-6 shadow-md rounded-md border border-gray-200">
            <StageForm />
          </div>

          <div className="lg:col-span-2 bg-white p-6 shadow-md rounded-md border border-gray-200">
            <StageTable />
          </div>
        </div>
      </div>

      {/* Billing Modal */}
      <BillingModal
        isOpen={isBillingModalOpen}
        onClose={() => setIsBillingModalOpen(false)}
      />
    </>
  );
}
