// src/components/Dashboard/StageForm.js
import { addDoc, collection, getDocs, query, where } from "firebase/firestore";
import React, { useEffect, useRef, useState } from "react";
import { TwitterPicker } from "react-color";
import { useAuth } from "../../contexts/AuthContext";
import { firestore } from "../../firebase";
import CloudinaryUpload from '../../services/Cloudinary';

export default function StageForm() {
  const { currentUser, accountData } = useAuth();
  const [eventData, setEventData] = useState({
    event_name: "",
    header_code: "",
    custom_slug: "",
    logo_url: "",
    title: "",
    colors: { header: "#000000", background: "#ffffff" },
    password_protection: false,
    password: "",
    video_url: "", 
  });
  const [error, setError] = useState("");
  const [showHeaderPicker, setShowHeaderPicker] = useState(false);
  const [showBackgroundPicker, setShowBackgroundPicker] = useState(false);
  const headerPickerRef = useRef(null);
  const backgroundPickerRef = useRef(null);
  const validSlugPattern = /^[a-zA-Z0-9-_]*$/;
  const handleSlugChange = (e) => {
    const value = e.target.value;
    
    if (validSlugPattern.test(value)) {
      setError(""); // Clear error if valid
      handleInputChange(e); // Proceed with form's existing input handler
    } else {
      setError("Only letters, numbers, '-' or '_' are allowed. No spaces or other special characters.");
    }
  };

  // Close colour pickers if clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        headerPickerRef.current &&
        !headerPickerRef.current.contains(event.target)
      ) {
        setShowHeaderPicker(false);
      }
      if (
        backgroundPickerRef.current &&
        !backgroundPickerRef.current.contains(event.target)
      ) {
        setShowBackgroundPicker(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleLogoUpload = (url) => {
    setEventData((prev) => ({
      ...prev,
      logo_url: url,
    }));
  };


  async function handleSubmit(e) {
    e.preventDefault();

    // Check if custom_slug is unique
    const q = query(
      collection(firestore, "events"),
      where("custom_slug", "==", eventData.custom_slug),
      where("is_public", "==", true)
    );
    const slugSnapshot = await getDocs(q);
    if (!slugSnapshot.empty) {
      setError("This URL is not usable, please choose another.");
      return;
    }

    if (!accountData) {
      setError("Account data not found.");
      return;
    }

    // Prepare event data
    const event = {
      ...eventData,
      user_id: currentUser.uid,
      account_id: accountData.id,
      created_date: new Date(),
      password: eventData.password_protection ? eventData.password : "",
      is_public: false,
    };

    try {
      // Add event to Firestore
      const eventCollection = collection(firestore, "events");
      await addDoc(eventCollection, event);

      // Reset form on success
      setEventData({
        event_name: "",
        header_code: "",
        custom_slug: "",
        logo_url: "",
        title: "",
        colors: { header: "#000000", background: "#ffffff" },
        password_protection: false,
        password: "",
        video_url: "",
      });
      setError(""); // Clear any existing errors
    } catch (error) {
      console.error("Error creating event:", error);
      setError(error.message);
    }
  }

  function handleInputChange(e) {
    const { name, value, type, checked } = e.target;
    if (type === "checkbox") {
      setEventData((prev) => ({ ...prev, [name]: checked }));
    } else {
      setEventData((prev) => ({ ...prev, [name]: value }));
    }
  }


  
  return (
    <form onSubmit={handleSubmit}>
      <div className="space-y-12">
        <div className="border-b border-gray-900/10 pb-12">
          <h2 className="text-lg font-semibold leading-7 text-gray-900">
            Create Stage
          </h2>
          <p className="mt-1 text-sm leading-6 text-gray-600">
            Please fill out the details for your stage.
          </p>

          {error && <div className="text-red-500 mb-4">{error}</div>}

          <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            {/* Stage Name */}
            <div className="sm:col-span-full">
              <div className="rounded-md px-3 pb-1.5 pt-2.5 shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-indigo-600">
                <label
                  htmlFor="event_name"
                  className="block text-xs font-medium text-gray-900"
                >
                  Stage Name
                </label>
                <input
                  id="event_name"
                  name="event_name"
                  type="text"
                  value={eventData.event_name}
                  onChange={handleInputChange}
                  required
                  placeholder="Event Name"
                  className="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            {/* Custom URL Slug */}
            <div className="sm:col-span-full">
              <div className="rounded-md px-3 pb-1.5 pt-2.5 shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-indigo-600">
                <label
                  htmlFor="custom_slug"
                  className="block text-xs font-medium text-gray-900"
                >
                  Your Stage URL
                </label>
                <div className="flex">
                  <span className="flex select-none items-center pl-3 text-gray-500 sm:text-sm">
                    https://app.vdopage.com/s/
                  </span>
                  <input
                    id="custom_slug"
                    name="custom_slug"
                    type="text"
                    value={eventData.custom_slug}
                    onChange={handleSlugChange}
                    required
                    placeholder="YOUR_URL"
                    className="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              {error && <p className="mt-1 text-sm text-red-600">{error}</p>}
            </div>

              {/* Logo URL Upload */}
              <div className="sm:col-span-full">
                <div className="rounded-md px-3 pb-1.5 pt-2.5 shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-indigo-600">
                  <label
                    htmlFor="logo_url"
                    className="block text-xs font-medium text-gray-900"
                  >
                    Logo Upload (optional)
                  </label>
                  <CloudinaryUpload setImageUrl={(url) => setEventData((prev) => ({ ...prev, logo_url: url }))} />
                </div>
              </div>

            {/* Colour Pickers */}
            <div className="sm:col-span-full grid grid-cols-1 sm:grid-cols-2 gap-8">
              {/* Header Colour Picker */}
              <div className="relative">
                <div className="flex flex-col items-center">
                  <label className="block text-center text-xs font-medium text-gray-900 mb-2">
                    Header Colour
                  </label>
                  <div
                    className="w-32 h-16 bg-white rounded-md shadow-sm ring-1 ring-inset ring-gray-300 cursor-pointer"
                    style={{
                      backgroundColor: eventData.colors.header || "#808080",
                    }}
                    onClick={() => setShowHeaderPicker(!showHeaderPicker)}
                  ></div>
                </div>
                {showHeaderPicker && (
                  <div
                    ref={headerPickerRef}
                    className="absolute mt-2 z-10 p-4 bg-white rounded-md shadow-lg"
                  >
                    <TwitterPicker
                      color={eventData.colors.header || "#808080"}
                      onChangeComplete={(color) => {
                        setEventData((prev) => ({
                          ...prev,
                          colors: { ...prev.colors, header: color.hex },
                        }));
                      }}
                      onChange={(color) => {
                        setEventData((prev) => ({
                          ...prev,
                          colors: { ...prev.colors, header: color.hex },
                        }));
                      }}
                    />
                    <button
                      className="mt-2 bg-gray-200 hover:bg-gray-300 text-gray-800 font-bold py-1 px-4 rounded"
                      onClick={() => setShowHeaderPicker(false)}
                    >
                      Done
                    </button>
                  </div>
                )}
              </div>

              {/* Background Colour Picker */}
              <div className="relative">
                <div className="flex flex-col items-center">
                  <label className="block text-center text-xs font-medium text-gray-900 mb-2">
                    Page Colour
                  </label>
                  <div
                    className="w-32 h-16 bg-white rounded-md shadow-sm ring-1 ring-inset ring-gray-300 cursor-pointer"
                    style={{
                      backgroundColor: eventData.colors.background || "#ffffff",
                    }}
                    onClick={() =>
                      setShowBackgroundPicker(!showBackgroundPicker)
                    }
                  ></div>
                </div>
                {showBackgroundPicker && (
                  <div
                    ref={backgroundPickerRef}
                    className="absolute mt-2 z-10 p-4 bg-white rounded-md shadow-lg"
                  >
                    <TwitterPicker
                      color={eventData.colors.background || "#ffffff"}
                      onChangeComplete={(color) => {
                        setEventData((prev) => ({
                          ...prev,
                          colors: { ...prev.colors, background: color.hex },
                        }));
                      }}
                      onChange={(color) => {
                        setEventData((prev) => ({
                          ...prev,
                          colors: { ...prev.colors, background: color.hex },
                        }));
                      }}
                    />
                    <button
                      className="mt-2 bg-gray-200 hover:bg-gray-300 text-gray-800 font-bold py-1 px-4 rounded"
                      onClick={() => setShowBackgroundPicker(false)}
                    >
                      Done
                    </button>
                  </div>
                )}
              </div>
            </div>

            {/* Password Protection */}
            <div className="sm:col-span-full">
              <div className="rounded-md px-3 pb-1.5 pt-2.5 shadow-sm ring-1 ring-inset ring-gray-300">
                <div className="flex items-center gap-x-3">
                  <input
                    id="password_protection"
                    name="password_protection"
                    type="checkbox"
                    checked={eventData.password_protection}
                    onChange={handleInputChange}
                    className="h-4 w-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
                  />
                  <label
                    htmlFor="password_protection"
                    className="text-sm font-medium text-gray-900"
                  >
                    Enable Password Protection
                  </label>
                </div>
                {eventData.password_protection && (
                  <input
                    type="password"
                    name="password"
                    value={eventData.password}
                    onChange={handleInputChange}
                    required
                    className="mt-2 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    placeholder="Set a password"
                  />
                )}
              </div>
            </div>

            {/* Iframe Input */}
            <div className="sm:col-span-full">
              <div className="rounded-md px-3 pb-1.5 pt-2.5 shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-indigo-600">
                <label
                  htmlFor="video_url"
                  className="block text-sm font-medium text-gray-900"
                >
                  URL of the video
                </label>
                <p className="text-xs text-gray-500">
                  Please provide only the URL from your iframe code. Do not include any HTML or iframe tags.
                </p>
                <input
                  id="video_url"
                  name="video_url"
                  type="url"
                  value={eventData.video_url}
                  onChange={handleInputChange}
                  required
                  placeholder="https://example.com/video-url"
                  className="mt-2 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-600 focus:border-indigo-600 sm:text-sm py-2"
                />
              </div>
            </div>
          </div>

          {/* Submit Button */}
          <div className="mt-6 flex items-center justify-end gap-x-6">
            <button
              type="button"
              className="text-sm font-semibold leading-6 text-gray-900"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500"
            >
              Create Stage
            </button>
          </div>
        </div>
      </div>
    </form>
  );
}
