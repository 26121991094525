// src/pages/MessageLayout.js
import React from "react";
import { ExclamationTriangleIcon } from '@heroicons/react/20/solid';

export default function MessageLayout({ message, type }) {
  if (type === "loading") {
    return (
      <div className="flex flex-col justify-center items-center h-screen">
        <h1 className="text-4xl font-bold text-gray-800 mb-4">Loading...</h1>
        <div className="flex flex-row gap-2">
          <div className="w-6 h-6 rounded-full bg-[#FF3A20] animate-bounce"></div>
          <div className="w-6 h-6 rounded-full bg-[#FF3A20] animate-bounce [animation-delay:-.3s]"></div>
          <div className="w-6 h-6 rounded-full bg-[#FF3A20] animate-bounce [animation-delay:-.5s]"></div>
        </div>
        <p className="mt-4 text-sm text-gray-500">Powered by VdoPage.com</p>
      </div>
    );
  }

  if (type === "error") {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="rounded-md bg-red-50 p-4 max-w-md">
          <div className="flex">
            <div className="flex-shrink-0">
              <ExclamationTriangleIcon aria-hidden="true" className="h-5 w-5 text-red-400" />
            </div>
            <div className="ml-3">
              <h3 className="text-sm font-medium text-red-800">Video not found</h3>
              <div className="mt-2 text-sm text-red-700">
                <p>
                  Please check that the URL is correct, or if you are the owner of the account, please review your settings.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return null; // Default case if no type is provided
}