// src/pages/StagePage.js
import { collection, getDocs, query, where } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import VideoPlayer from "../components/Generic/VideoPlayer";
import MessageLayout from "../components/Layout/MessageLayout";
import PasswordProtection from "../components/Stage/PasswordProtection";
import StageNavBar from "../components/Stage/StageNavBar";
import { useAuth } from "../contexts/AuthContext"; // Correct import path
import { firestore } from "../firebase";

export default function StagePage({ previewMode = false }) {
  const { slug } = useParams();
  const [isEventLoaded, setIsEventLoaded] = useState(false);
  const [eventData, setEventData] = useState(null);
  const [passwordError, setPasswordError] = useState("");
  const [isAuthorised, setIsAuthorised] = useState(false);
  const { currentUser } = useAuth(); // Get the logged-in user and account data

  useEffect(() => {
    async function fetchEvent() {
      let q;

      if (previewMode) {
        q = query(
          collection(firestore, "events"),
          where("custom_slug", "==", slug),
          where("user_id", "==", currentUser.uid)
        );
      } else {
        q = query(
          collection(firestore, "events"),
          where("custom_slug", "==", slug),
          where("is_public", "==", true)
        );
      }
      try {
        const snapshot = await getDocs(q);
        if (!snapshot.empty) {
          const data = snapshot.docs[0].data();
          setEventData(data);
          if (!data.password_protection) {
            setIsAuthorised(true);
          }
        }
      } catch (e) {
        console.log("failed to get event", e);
      } finally {
        setIsEventLoaded(true);
      }
    }
    fetchEvent();
  }, [slug]);

  // Dynamically set the page title
  useEffect(() => {
    if (eventData?.event_name) {
      document.title = eventData.event_name;
    }
  }, [eventData]);

  // Dynamically inject custom header code
  useEffect(() => {
    if (eventData?.header_code) {
      const script = document.createElement("script");
      script.innerHTML = eventData.header_code;
      document.head.appendChild(script);
      return () => {
        document.head.removeChild(script);
      };
    }
  }, [eventData]);

  function checkPassword(passwordInput) {
    if (passwordInput === eventData.password) {
      setIsAuthorised(true);
      setPasswordError("");
    } else {
      setPasswordError("Incorrect password");
    }
  }

  if (!isEventLoaded) {
    return <MessageLayout message="Loading..." type="loading" />;
  }
  
  if (!eventData && isEventLoaded) {
    return <MessageLayout message="Video not found" type="error" />;
  }

  return (
    <div
      id="stage-page"
      className="min-h-screen flex flex-col"
      style={{ backgroundColor: eventData.colors.background }}
    >
      {/* Navigation bar */}
      <StageNavBar stage={eventData} />
      {previewMode ? (
        <div
          id="preview-mode"
          title="Preview Mode - This bar will not show in public views"
        >
          Preview Mode{" "}
          {eventData.is_public
            ? `- Publically viewable at /s/${eventData.custom_slug}`
            : " - Not publically viewable"}
        </div>
      ) : (
        <div />
      )}

      {/* Main content */}
      <div
        id="main-content"
        className="flex-1 flex items-center justify-center"
      >
        {!isAuthorised && eventData.password_protection ? (
          <PasswordProtection
            checkPassword={checkPassword}
            passwordError={passwordError}
          />
        ) : (
          <VideoPlayer video_url={eventData.video_url} />
        )}
      </div>
    </div>
  );
}
