import React, { useState } from 'react';

const CloudinaryUpload = ({ setImageUrl }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [uploadedImage, setUploadedImage] = useState('');

  const handleUpload = () => {
    setLoading(true);

    // Ensure window.cloudinary is available
    if (!window.cloudinary) {
      setLoading(false);
      setError('Cloudinary widget is not available');
      return;
    }

    window.cloudinary.openUploadWidget(
        {
          cloudName: process.env.REACT_APP_CLOUDINARY_CLOUD_NAME,
          uploadPreset: 'businessdaddy', // Make sure this is the correct preset name
          sources: ['local', 'url', 'camera'],
          multiple: false,
          maxFiles: 1,
          folder: 'vdopage_logos', // Make sure your folder is set correctly in the Cloudinary preset if used
          apiKey: process.env.REACT_APP_CLOUDINARY_API_KEY,
          resourceType: 'image',
        },
        (error, result) => {
          setLoading(false);
          if (error) {
            setError('Upload failed. Please try again.');
            console.error('Cloudinary Error:', error);
          } else if (result.event === 'success') {
            const imageUrl = result.info.secure_url;
            setUploadedImage(imageUrl); // Show preview
            setImageUrl(imageUrl); // Pass URL to parent component or form
            setError(''); // Clear any previous errors
          }
        }
      );
    };
  const handleRemoveImage = () => {
    setUploadedImage(''); // Clear the uploaded image
    setImageUrl(''); // Clear the URL in parent component or form
  };

  return (
    <div className="w-full">
      {/* Show the image if uploaded */}
      {uploadedImage ? (
        <div className="mb-4">
          <img
            src={uploadedImage}
            alt="Uploaded Preview"
            className="mb-2 w-20 h-40 object-contain"
          />
          <button
            type="button"
            className="px-4 py-2 bg-red-500 text-white text-sm rounded-md"
            onClick={handleRemoveImage}
          >
            Delete/Change Image
          </button>
        </div>
      ) : (
        <div>
          <label
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            htmlFor="file_input"
          >
            Upload file
          </label>
          <div
            onClick={handleUpload}
            className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
          >
            <p className="mt-1 text-sm text-gray-500 dark:text-gray-300" id="file_input_help">
              SVG, PNG, JPG, or GIF (MAX. 800x400px)
            </p>
          </div>
          {loading && <p className="text-sm text-blue-500 mt-2">Uploading...</p>}
          {error && <p className="text-sm text-red-500 mt-2">{error}</p>}
        </div>
      )}
    </div>
  );
};

export default CloudinaryUpload;